@tailwind base;
@tailwind components;
@tailwind utilities;


/* Hide the first motto-strip on mobile devices */
@media only screen and (max-width: 768px) {
    .motto-strip.hide-on-mobile {
      display: none;
    }
  }
  